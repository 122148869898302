<eaw-page-header>
    <span title>{{ 'PAYROLL_PERIOD_plural' | translate:'payroll' | async }}</span>
</eaw-page-header>

<mat-card>
    <mat-card-content class="tw-grid tw-grid-cols-1 md:tw-grid-cols-3 lg:tw-grid-cols-4 tw-gap-16">
        <mat-form-field class="tw-col">
            <mat-label>{{ 'YEAR' | translate | async }}</mat-label>
            <input matInput [formControl]="year" autocomplete="off" (input)="onInputChange($event)" type="number" step="1" [min]="minYear" [max]="maxYear">
        </mat-form-field>
        <eaw-action-button class="md:tw-row-start-2" [loading]="gettingPeriods()" [disabled]="!this.year.valid" (click)="getPeriods()">{{ 'UPDATE_LIST' | translate | async }}</eaw-action-button>
    </mat-card-content>
</mat-card>

<mat-card id="table-card" [class.getting]="gettingPeriods()">
    @if (gettingPeriods()) {
        <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
    } @else {
        <mat-card-header>
            <mat-card-title>
                {{'PAYROLL_PERIOD_plural' | translate: 'payroll' | async }}
            </mat-card-title>
            <div class="buttons">
                <button mat-mini-fab color="accent" [matTooltip]="('SAVE' | translate | async) || ''" [disabled]="!changesAwaiting()" matTooltipPosition="left" (click)="submit()">
                    <mat-icon>{{'save'}}</mat-icon>
                </button>
                <button mat-icon-button [matTooltip]="((locked() ? 'LOCKED' : 'NOT_LOCKED') | translate: Namespace.Widgets | async) || ''" [disabled]="locked()" matTooltipPosition="left" (click)="lock()">
                    <mat-icon>{{locked() ? 'lock' : 'lock_open'}}</mat-icon>
                </button>
                @if (canDelete()) {
                    <button mat-icon-button [matTooltip]="('DELETE' | translate | async) || ''" [disabled]="locked()" matTooltipPosition="left" (click)="revert()">
                        <mat-icon>{{'delete'}}</mat-icon>
                    </button>
                }
            </div>
        </mat-card-header>

        <mat-card-content class="tw-p-0 tw-overflow-auto">
            <form [formGroup]="datesForm">
                <table mat-table [dataSource]="periods()">
                    @for (column of columns(); track column.columnDef; let firstCol = $first) {
                        <ng-container [matColumnDef]="column.columnDef">
                            <th mat-header-cell [class.first-column]="firstCol" *matHeaderCellDef [title]="column.label | async">
                                {{ column.label | async }}
                            </th>

                            <td mat-cell [class.first-column]="firstCol" *matCellDef="let item">
                                @if (column.type === 'string') {
                                    {{(item.label | async)}}
                                } @else {
                                    @if (dataSource()?.locked || !canUpdate()) {
                                        {{(item[column.columnDef] |  DateTime: 'DATE_MED')}}
                                    } @else if (column.columnDef === 'start') {
                                        @if (item.month === 'january') {
                                            <mat-form-field [formGroupName]="item.month">
                                                <input matInput formControlName="start" [matDatepicker]="toPicker" [readonly]="true">
                                                <mat-datepicker-toggle matSuffix [for]="toPicker"></mat-datepicker-toggle>
                                                <mat-datepicker #toPicker></mat-datepicker>
                                            </mat-form-field>
                                        } @else {
                                            {{(item[column.columnDef] |  DateTime: 'DATE_MED')}}
                                        }
                                    } @else if (column.columnDef === 'end') {
                                        <mat-form-field [formGroupName]="item.month">
                                            <input matInput formControlName="end" [matDatepicker]="toPicker" [readonly]="true">
                                            <mat-datepicker-toggle matSuffix [for]="toPicker"></mat-datepicker-toggle>
                                            <mat-datepicker #toPicker></mat-datepicker>
                                        </mat-form-field>
                                    }
                                }
                            </td>
                        </ng-container>
                    }

                    <tr mat-header-row *matHeaderRowDef="columnsDef(); sticky: true"></tr>
                    <tr mat-row *matRowDef="let row; columns: columnsDef();"></tr>
                </table>
            </form>
        </mat-card-content>
    }
</mat-card>
