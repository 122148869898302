import { Pipe, PipeTransform } from '@angular/core';
import { Duration, DurationObjectUnits, Settings } from 'luxon';
import { DurationUnit } from 'luxon/src/duration';

interface ExtraOptions {
    keepZeros?: boolean;
}

@Pipe({
    name: 'duration',
    standalone: true,
})
/**
 * Pipe to format a duration in seconds to a human readable string.
 *
 * The seconds provided to this pipe should not be manipulated inside the pipe.
 * Any changes or adjustments should be done **before** passing the value to the pipe.
 */
export class DurationPipe implements PipeTransform {
    transform(seconds?: Duration | number | null | unknown, units: DurationUnit[] = [], options?: Intl.NumberFormatOptions, extraOptions?: ExtraOptions): string {
        if (seconds == null) {
            return '';
        }
        if (seconds instanceof Duration) {
            return DurationPipe.format(seconds, units, options, extraOptions);
        }
        if (typeof seconds === 'number') {
            return DurationPipe.format(Duration.fromObject({ seconds }), units, options, extraOptions);
        }

        return '';
    }

    private static getObject(duration: Duration, units: DurationUnit[], extraOptions?: ExtraOptions) {
        const durationUnits: DurationUnit[] = units.length ? units : [ 'hours', 'minutes' ];
        return Object.entries(duration.shiftTo(...durationUnits).toObject()).reduce((acc, [ key, value ]) => {
            if (value === 0) {
                return extraOptions?.keepZeros ? { ...acc, [key]: value } : acc;
            }

            return { ...acc, [key]: value };
        }, {} as DurationObjectUnits);
    }

    private static format(duration: Duration, units: DurationUnit[], options?: Intl.NumberFormatOptions, extraOptions?: ExtraOptions) {
        const object = duration.as('seconds') ? DurationPipe.getObject(duration, units, extraOptions) : { minutes: 0 };
        let replaceSearch: RegExp = new RegExp('');
        let replaceValue = '';

        if (Settings.defaultLocale.startsWith('de')) {
            replaceSearch = /\sund/;
            replaceValue = ',';
        }

        try {
            return Duration.fromObject(object).toHuman({
                unitDisplay: 'narrow',
                listStyle: 'narrow',
                maximumFractionDigits: 0,
                ...options,
            }).replace(replaceSearch, replaceValue);
        } catch (e) {
            console.error(`Can't do human`, e);
            return '';
        }
    }
}
