import { Component, computed, effect, Inject, OnInit, signal, ViewChild } from '@angular/core';
import { DataTableComponent, DataTableGoTo } from '../../../data-table/data-table.component';
import { HeaderFabButton, PageHeaderComponent } from '../../../shared/components/page-header/page-header.component';
import { DataTablePagination, DataTableRequest, EawDataTable } from '../../../data-table/types/data-table';
import { catchError, EMPTY, map, of, tap } from 'rxjs';
import { DataTableColumnType } from '../../../data-table/interfaces/data-table-columns';
import { DataTableTextColumn } from '../../../data-table/types/data-table-text-column';
import { DataTableHeader } from '../../../data-table/types/data-table-header';
import { DateTime } from 'luxon';
import { DataTableButtonColumn } from '../../../data-table/types/data-table-button-column';
import { ScheduleTemplate } from '../../models/schedule-template';
import { DataTableCell } from '../../../data-table/interfaces/data-table-cell';
import { FormControl, Validators } from '@angular/forms';
import { CurrentService } from '../../../shared/services/current.service';
import { PromptDialogService } from '../../../shared/dialogs/prompt-dialog/prompt-dialog.service';
import { TranslateService } from '../../../shared/services/translate.service';
import { ScheduleTemplateService } from '../../http/schedule-template.service';
import { DurationPipe } from '../../../shared/pipes/duration.pipe';
import { Products } from '../../../shared/enums/products';
import { ConfirmDialogService } from '../../../shared/dialogs/confirm-dialog/confirm-dialog.service';
import { CreateTemplateDialogService } from '../../dialogs/create-template-dialog/create-template-dialog.service';
import { DataTableDateTimeColumn } from '../../../data-table/types/data-table-date-time-column';
import { CreateRepeatingScheduleTemplateDialogData, RepeatingTemplateCreateComponent } from '../../dialogs/create-template-dialog/repeating-template-create/repeating-template-create.component';
import { MatDialog } from '@angular/material/dialog';
import { PermissionCheckService } from '../../../shared/services/permission-check.service';
import { CustomerProductService } from '../../../shared/http/customer-product.service';
import { ApiModel } from '../../../shared/enums/api-model';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { AsyncPipe } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { mockArrayPaginatedResponse } from '../../../../mocks/paginated-response.mock';
import { MatButton } from '@angular/material/button';
import { PermissionDirective } from '../../../permissions/directives/permission.directive';

@Component({
    selector: 'eaw-template-list',
    templateUrl: './template-list.component.html',
    styleUrl: './template-list.component.scss',
    providers: [ DurationPipe ],
    standalone: true,
    imports: [
        PageHeaderComponent,
        MatCardModule,
        DataTableComponent,
        AsyncPipe,
        TranslatePipe,
        MatButton,
        PermissionDirective,
    ],
})
export class TemplateListComponent implements EawDataTable, OnInit {
    @ViewChild(DataTableComponent) table!: DataTableComponent<ScheduleTemplate>;

    fabButton: HeaderFabButton = {
        click: this.createTemplate.bind(this),
        hasPermission: () => this.permissionCheckService.isAllowed(`customers.${this.current.getCustomer().id}.schedules.create`),
    };

    usingBetaSchedule = signal(false);
    goTo = computed<DataTableGoTo>(() => {
        return {
            state: this.usingBetaSchedule() ? 'eaw/app/scheduling/schedules/list/beta' : 'eaw/app/scheduling/schedules/templates/view',
            params: [ { stateKey: 'id', itemKey: 'id' } ],
        };
    });

    private betaScheduleKey = 'usingBetaSchedule';
    request?: DataTableRequest = of(mockArrayPaginatedResponse());
    columns: DataTableColumnType<ScheduleTemplate>[] = [
        new DataTableTextColumn({
            value: 'name',
            header: new DataTableHeader({
                i18n: 'NAME',
                sortBy: 'name',
            }),
        }),
        new DataTableTextColumn({
            header: new DataTableHeader({
                i18n: 'LENGTH',
                sortBy: 'length',
            }),
            value: this.scheduleLength.bind(this),
        }),
        new DataTableDateTimeColumn({
            value: 'createdAt',
            header: new DataTableHeader({
                i18n: 'CREATED',
                sortBy: 'created_at',
            }),
            format: DateTime.DATETIME_MED,
        }),
        new DataTableButtonColumn({
            buttons: [
                {
                    click: this.update.bind(this),
                    icon: 'edit',
                    tooltip: { key: 'EDIT' },
                    show: this.canUpdate.bind(this),
                },
                {
                    click: this.copy.bind(this),
                    icon: 'content_copy',
                    tooltip: { key: 'COPY' },
                    show: this.canUpdate.bind(this),
                },
                {
                    click: this.delete.bind(this),
                    icon: 'delete',
                    tooltip: { key: 'DELETE' },
                    type: 'warn',
                    show: this.canDelete.bind(this),
                },
            ],
        }),
    ];

    constructor(
        @Inject(ScheduleTemplateService) private scheduleTemplateService: ScheduleTemplateService,
        @Inject(CurrentService) private current: CurrentService,
        @Inject(PromptDialogService) private promptDialog: PromptDialogService,
        @Inject(TranslateService) private translate: TranslateService,
        @Inject(CreateTemplateDialogService) private createTemplateDialog: CreateTemplateDialogService,
        @Inject(PermissionCheckService) private permissionCheckService: PermissionCheckService,
        @Inject(DurationPipe) private durationPipe: DurationPipe,
        @Inject(ConfirmDialogService) private confirmDialog: ConfirmDialogService,
        @Inject(MatDialog) private matDialog: MatDialog,
        @Inject(CustomerProductService) private customerProductService: CustomerProductService,
    ) {
        effect(() => this.current.store(this.betaScheduleKey, this.usingBetaSchedule(), 'default'));
        this.current.retrieve<boolean>(this.betaScheduleKey, 'default').then((val) => this.usingBetaSchedule.set(val ?? false));
    }

    ngOnInit() {
        this.hasRepeatingSchedule().pipe(map((hasRepeating: boolean) => {
            if (hasRepeating) {
                this.columns.splice(2, 0, new DataTableTextColumn({
                    header: new DataTableHeader({
                        i18n: 'START',
                        sortBy: 'from',
                    }),
                    value: (cell) => cell.item.from.toLocaleString(DateTime.DATETIME_MED),
                }));
            }
        })).subscribe();
    }

    private hasRepeatingSchedule() {
        return this.customerProductService.hasProducts(this.current.getCustomer().id, [ Products.RepeatingSchedule ]);
    }

    canUpdate(template: ScheduleTemplate) {
        return this.permissionCheckService.isAllowed(`customers.[${ApiModel.Customer}].schedules.[${ApiModel.Schedule}].update`, {
            stackId: this.current.getCustomer().stackId,
            models: [
                { type: ApiModel.Schedule, id: template.id },
                { type: ApiModel.Customer, id: template.customerId },
            ],
        });
    }

    canDelete(template: ScheduleTemplate) {
        return this.permissionCheckService.isAllowed(`customers.[${ApiModel.Customer}].schedules.[${ApiModel.Schedule}].delete`, {
            stackId: this.current.getCustomer().stackId,
            models: [
                { type: ApiModel.Customer, id: template.customerId },
                { type: ApiModel.Schedule, id: template.id },
            ],
        });
    }

    async copy(cell: DataTableCell<DataTableButtonColumn<ScheduleTemplate>, ScheduleTemplate>) {
        this.promptDialog.open('text', {
            formControl: new FormControl(cell.item.name, Validators.required),
            confirmText: this.translate.t('COPY'),
            label: this.translate.t('NAME'),
            text: this.translate.t('COPY_SCHEDULE_TEMPLATE_TEXT', 'scheduling', { name: cell.item.name }),
            title: this.translate.t('COPY_SCHEDULE_TEMPLATE', 'scheduling'),
        }).afterClosed().subscribe((val) => {
            if (val == null) {
                cell.disabled.set(false);
                return;
            }

            this.scheduleTemplateService.copy(cell.item.customerId, cell.item.id, val, cell.item.from).pipe(
                catchError((err) => {
                    console.error(err);
                    cell.disabled.set(false);
                    return EMPTY;
                }),
            ).subscribe(() => {
                this.table.refresh();
            });
        });
    }

    createTemplate() {
        this.createTemplateDialog.open({ customerId: this.current.getCustomer().id }).subscribe((result) => {
            if (result == null) {
                return;
            }

            this.table.refresh();
        });
    }

    delete(cell: DataTableCell<DataTableButtonColumn<ScheduleTemplate>, ScheduleTemplate>) {
        this.confirmDialog.open({
            title: this.translate.t('DELETE'),
            text: this.translate.t('DELETE_SCHEDULE_TEMPLATE', 'scheduling', { name: cell.item.name }),
            confirmText: this.translate.t('DELETE'),
        }).afterClosed().subscribe((val) => {
            if (!val?.ok) {
                cell.disabled.set(false);
                return;
            }

            this.scheduleTemplateService.delete(cell.item.customerId, cell.item.id)
                .pipe(
                    catchError((err) => {
                        console.error(err);
                        cell.disabled.set(false);
                        return EMPTY;
                    }),
                )
                .subscribe(() => {
                    this.updateTable(this.table.getPagination({ page: 1 }));
                });
        });
    }

    scheduleLength(cell: DataTableCell<DataTableTextColumn<ScheduleTemplate>, ScheduleTemplate>): string {
        return this.durationPipe.transform(cell.item.length, [ 'weeks', 'days' ], { unitDisplay: 'long' });
    }

    updateRepeating(cell: DataTableCell<DataTableButtonColumn<ScheduleTemplate>, ScheduleTemplate>) {
        this.matDialog.open<RepeatingTemplateCreateComponent, CreateRepeatingScheduleTemplateDialogData, ScheduleTemplate>(RepeatingTemplateCreateComponent, {
            data: {
                customerId: cell.item.customerId,
                edit: true,
                schedule: cell.item,
            },
        }).afterClosed().subscribe((result) => {
            cell.disabled.set(result instanceof ScheduleTemplate);

            if (result instanceof ScheduleTemplate) {
                this.updateTable(this.table.getPagination());
            }
        });
    }

    updateDefault(cell: DataTableCell<DataTableButtonColumn<ScheduleTemplate>, ScheduleTemplate>) {
        this.promptDialog.open('text', {
            formControl: new FormControl(cell.item.name),
            confirmText: this.translate.t('UPDATE'),
            label: this.translate.t('NAME'),
            title: this.translate.t('EDIT_SCHEDULE', 'scheduling'),
        }).afterClosed().subscribe((val) => {
            if (val == null) {
                cell.disabled.set(false);
                return;
            }

            this.scheduleTemplateService.update(cell.item.customerId, cell.item.id, { name: val }).pipe(
                catchError((err) => {
                    console.error(err);
                    cell.disabled.set(false);
                    return EMPTY;
                }),
            ).subscribe(() => {
                this.updateTable(this.table.getPagination());
            });
        });
    }

    update(cell: DataTableCell<DataTableButtonColumn<ScheduleTemplate>, ScheduleTemplate>) {
        this.hasRepeatingSchedule().pipe(
            tap((hasRepeating) => {
                if (hasRepeating) {
                    this.updateRepeating(cell);
                } else {
                    this.updateDefault(cell);
                }
            }),
        );
    }

    updateTable(pagination: Partial<DataTablePagination>): void {
        this.request = this.scheduleTemplateService.getAll(this.current.getCustomer().id, {
            ...pagination,
        });
    }
}
