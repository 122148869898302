import { AfterViewInit, ChangeDetectionStrategy, Component, computed, DestroyRef, effect, ElementRef, inject, input, OnDestroy, signal, viewChild } from '@angular/core';
import { ScheduleComponent } from '../../schedule.component';
import { DateTime, Interval } from 'luxon';
import { DateTimePipe } from '../../../../../shared/pipes/date-time.pipe';
import { ScheduleTabCreateShiftRowComponent } from './components/schedule-tab-create-shift-row/schedule-tab-create-shift-row.component';
import { ScheduleTabDefaultDisplayComponent } from './components/schedule-tab-default-display/schedule-tab-default-display.component';
import { ScheduleTabMenuComponent } from './components/schedule-tab-menu/schedule-tab-menu.component';
import { fromEvent } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NumberPipe } from '../../../../../shared/pipes/number.pipe';
import { MatIcon } from '@angular/material/icon';
import { MatIconRoundedDirective } from '../../../../../shared/directives/mat-icon-rounded.directive';
import { MatIconSizeDirective } from '../../../../../shared/directives/mat-icon-size.directive';
import { MaterialColorDirective } from '../../../../../shared/directives/material-color.directive';
import { MatTooltip } from '@angular/material/tooltip';
import { TranslatePipe } from '../../../../../shared/pipes/translate.pipe';
import { AsyncPipe, NgStyle } from '@angular/common';
import { ScheduleTabTopStatsComponent } from './components/schedule-tab-top-stats/schedule-tab-top-stats.component';

export interface ScheduleTabInterval {
    index: number;
    offset: number;
    time: DateTime;
    dayKey: string;
    humanTime: string;
    interval: Interval;
}

@Component({
    selector: 'eaw-schedule-tab',
    standalone: true,
    imports: [
        DateTimePipe,
        ScheduleTabCreateShiftRowComponent,
        ScheduleTabDefaultDisplayComponent,
        ScheduleTabMenuComponent,
        NumberPipe,
        MatIcon,
        MatIconRoundedDirective,
        MatIconSizeDirective,
        MaterialColorDirective,
        MatTooltip,
        TranslatePipe,
        NgStyle,
        AsyncPipe,
        ScheduleTabTopStatsComponent,
    ],
    templateUrl: './schedule-tab.component.html',
    styleUrl: './schedule-tab.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScheduleTabComponent implements AfterViewInit, OnDestroy {
    private el = inject(ElementRef) as ElementRef<HTMLElement>;
    private destroyRef = inject(DestroyRef);

    private shiftsDisplayContainer = viewChild.required('displayContainer', { read: ElementRef<HTMLElement> });
    protected topStatsComponent = viewChild(ScheduleTabTopStatsComponent);

    stackId = input.required<number>();
    customerId = input.required<number>();
    scheduleId = input.required<number>();

    private readonly cellWidth = signal(64);

    private destroySignal = new AbortController();
    private resizeObserver?: ResizeObserver;

    schedulePublished = computed(() => ScheduleComponent.schedule()?.isPublished ?? false);
    shiftsCount = computed(this.computeShiftsCount.bind(this));
    intervals = computed<ScheduleTabInterval[]>(this.computeIntervals.bind(this));
    days = computed(this.computeDays.bind(this));
    pixelsPerSecond = computed(() => this.cellWidth() / ScheduleComponent.properties.scheduleTab.interval.value());
    scrollOffset = signal(0);
    containerWidth = signal(0);
    showTopStats = computed(() => ScheduleComponent.properties.scheduleTab.topStatsEnabled.value());
    intervalToRenderFrom = computed(() => Math.floor(this.scrollOffset() / this.cellWidth()));
    numberOfIntervalsToRender = computed(() => Math.ceil(this.containerWidth() / this.cellWidth()) + 1);
    /**
     * The intervals that are visible for the user (in the viewport)
     */
    renderedIntervals = computed(() => this.intervals().slice(this.intervalToRenderFrom(), this.intervalToRenderFrom() + this.numberOfIntervalsToRender()));

    constructor() {
        effect(() => {
            this.el.nativeElement.style.setProperty('--schedule-tab-intervals', this.intervals().length.toString());
        });

        effect(() => {
            this.el.nativeElement.style.setProperty('--schedule-tab-interval-cell-width', `${this.cellWidth()}px`);
        });

        effect(() => {
            this.el.nativeElement.style.setProperty('--schedule-tab-schedule-width', `${this.cellWidth() * this.intervals().length}px`);
        });

        effect(() => {
            this.el.nativeElement.style.setProperty('--schedule-tab-interval-offset', String(this.renderedIntervals()[0]?.index || 0));
        });
    }

    ngAfterViewInit() {
        this.watchScroll(this.shiftsDisplayContainer().nativeElement, this.el.nativeElement);
        this.watchResize(this.el.nativeElement);
    }

    ngOnDestroy() {
        this.destroySignal.abort();
        this.resizeObserver?.disconnect();
    }

    protected goToDay(change: -1 | 1) {
        // TODO: Handle schedule day change in another PR, in conjunction with the day selector for the sidebar
        console.debug(`goToDay`, change);
    }

    private watchScroll(target: HTMLElement, componentElement: HTMLElement) {
        fromEvent(target, 'scroll', { passive: true }).pipe(
            takeUntilDestroyed(this.destroyRef),
        ).subscribe(() => {
            this.scrollOffset.set(target.scrollLeft);
            componentElement.style.setProperty('--schedule-tab-scroll-offset', `${target.scrollLeft}px`);
        });
    }

    private watchResize(element: HTMLElement) {
        this.resizeObserver = new ResizeObserver(() => this.containerWidth.set(element.getBoundingClientRect().width));
        this.resizeObserver.observe(element);
    }

    private computeShiftsCount() {
        return Array.from(ScheduleComponent.shifts().values()).reduce<{ all: number, unassigned: number }>((acc, shift) => {
            acc.all++;

            if (!shift.employeeId) {
                acc.unassigned++;
            }

            return acc;
        }, { all: 0, unassigned: 0 });
    }

    private computeDays() {
        return ScheduleComponent.getDays(ScheduleComponent.schedule()).map((d) => {
            return {
                day: d,
                intervals: this.intervals().filter((i) => i.dayKey === d.dateTime.toISODate()).length,
            };
        }).filter((d) => d.intervals > 0);
    }

    private computeIntervals() {
        const interval = ScheduleComponent.properties.scheduleTab.interval.value();
        const schedule = ScheduleComponent.schedule();
        if (!schedule) {
            return [];
        }

        const { from, to } = schedule;
        const intervals: ScheduleTabInterval[] = [];
        const intervalCount = to.diff(from, 'seconds').as('seconds') / interval;

        for (let i = 0; i < intervalCount; i++) {
            const offset = i * interval;
            const time = from.plus({ seconds: offset });

            intervals.push({
                index: i,
                offset,
                time,
                dayKey: time.toISODate() || '',
                humanTime: time.toLocaleString(DateTime.TIME_SIMPLE),
                interval: Interval.fromDateTimes(time, time.plus({ seconds: interval })),
            });
        }

        return intervals;
    }

    protected onHeaderWheelEvent(event: WheelEvent) {
        this.shiftsDisplayContainer().nativeElement.scrollLeft += event.deltaX;
    }

    /**
     * Find the closest half interval to the given offset (in seconds)
     *
     * @param offset The offset in seconds
     * @param options Options
     * @param options.onlyLookBackwards Only look backwards to find the closest previous half interval
     * @param options.useHalf Use half of the interval as the base unit
     */
    static findClosestInterval(offset: number, options?: { onlyLookBackwards?: boolean, useHalf?: boolean }) {
        const interval = ScheduleComponent.properties.scheduleTab.interval.value();
        const divider = options?.useHalf ? 2 : 1;

        const intervals = Math.floor(offset / (interval / divider));
        const reminder = offset % (interval / divider);
        const prev = intervals;
        const next = options?.onlyLookBackwards ? intervals : intervals + 1;

        return (reminder >= (interval / (divider * 2)) ? next : prev) * (interval / divider);
    }
}
