import { BaseSchedule } from './base-schedule';

export class Schedule extends BaseSchedule {
    override isTemplate = false;

    /**
     * Returns the length of the schedule adjusted for daylight saving time.
     */
    dstAdjustedLength() {
        if (!this.from.isInDST && this.to.isInDST) {
            return this.length + 3600;
        } else if (this.from.isInDST && !this.to.isInDST) {
            return this.length - 3600;
        } else {
            return this.length;
        }
    }
}
