export const PredefinedSettings = {
    /**
     * Fields that are required when creating a new employee
     */
    NewEmployeeRequiredFields: 'newemployee.required_fields',
    /**
     * Max number of user groups a user can be a member of
     */
    MaxUserGroups: 'max_user_groups',
    /**
     * Enable or disable the timepunch blocking feature
     */
    PayrollTimepunchBlocksEnabled: 'payroll.timepunch_blocks_enabled',
    /**
     * Required to use the timepunch clock in the app
     */
    PayrollPersonalTpApp: 'payroll.personal_tpapp',
    /**
     * The balances that the employee will see when checking "My balances"
     */
    EmployeeVisibleBalanceCodes: 'employee_visible_balance_codes',
    /**
     * Don't know what this is
     */
    EmployeeAbsencePayPeriodExempt: 'employee.absence_pay_period_exempt',
    /**
     * Enable the family member functionality
     */
    FamilyMemberEnabled: 'family_member.enabled',
    /**
     * Defines which day is used as the first day of the week (mon for Monday, sun for Sunday)
     */
    SchedulingWeekStart: 'scheduling.week_start',
    /**
     * Whether customer has access to payroll overview
     */
    PayrollOverviewEnabled: 'payroll.france_overview_enabled',
    /**
     * Whether customer has access to payroll periods
     */
    PayrollPeriodsEnabled: 'payroll.payroll_periods_enabled',
} as const;
