import { ChangeDetectionStrategy, Component, computed, inject, Input, OnInit, signal, Signal } from '@angular/core';
import { TranslatePipe } from '../../../shared/pipes/translate.pipe';
import { AsyncPipe, CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatTableModule } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { PageHeaderComponent } from '../../../shared/components/page-header/page-header.component';
import { CurrentService } from '../../../shared/services/current.service';
import { TranslateService } from '../../../shared/services/translate.service';
import { SignalInput } from '../../../shared/decorators/signal-input.decorator';
import { ActionButtonComponent } from '../../../shared/components/action-button/action-button.component';
import { PayrollPeriodsService } from '../../../payroll/http/payroll-periods.service';
import { catchError, EMPTY, of, Subscription } from 'rxjs';
import { DateTime } from 'luxon';
import { NumberPipe } from '../../../shared/pipes/number.pipe';
import { DateTimePipe } from '../../../shared/pipes/date-time.pipe';
import { MatIcon } from '@angular/material/icon';
import { MatIconSizeDirective } from '../../../shared/directives/mat-icon-size.directive';
import { MaterialColorDirective } from '../../../shared/directives/material-color.directive';
import { MatDatepicker, MatDatepickerInput, MatDatepickerToggle } from '@angular/material/datepicker';
import { MatButton, MatIconButton, MatMiniFabButton } from '@angular/material/button';
import { PermissionDirective } from '../../../permissions/directives/permission.directive';
import { MatTooltip } from '@angular/material/tooltip';
import { ConfirmDialogComponent, ConfirmDialogData, ConfirmDialogReturn } from '../../../shared/dialogs/confirm-dialog/confirm-dialog.component';
import { Namespace } from '../../../shared/enums/namespace';
import { MatDialog } from '@angular/material/dialog';
import { PermissionCheckService } from '../../../shared/services/permission-check.service';
import { PayrollPeriods } from '../../models/payroll-periods';

interface Column {
    columnDef: string;
    label: Promise<string>;
    type?: 'date' | 'string';
}
interface Period {
    month: string;
    label: Promise<string>;
    start?: DateTime;
    end?: DateTime;
}
type UpdateBodyKeys = 'january' | 'february' | 'march' | 'april' | 'may' | 'june' | 'july' | 'august' | 'september' | 'october' | 'november' | 'december' | 'end';

type UpdateBody = {
    [key in UpdateBodyKeys]: DateTime | undefined | null;
};
type Months = UpdateBodyKeys[];
@Component({
    selector: 'eaw-payroll-periods',
    standalone: true,
    imports: [
        CommonModule,
        MatCardModule,
        MatTableModule,
        MatFormFieldModule,
        MatInputModule,
        MatProgressSpinnerModule,
        ReactiveFormsModule,
        PageHeaderComponent,
        TranslatePipe,
        AsyncPipe,
        ActionButtonComponent,
        NumberPipe,
        DateTimePipe,
        MatIcon,
        MatIconSizeDirective,
        MaterialColorDirective,
        MatDatepickerInput,
        MatDatepicker,
        MatDatepickerToggle,
        MatIconButton,
        MatMiniFabButton,
        PermissionDirective,
        MatTooltip,
        MatButton,
    ],
    templateUrl: './payroll-periods.component.html',
    styleUrl: './payroll-periods.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PayrollPeriodsComponent implements OnInit {
    currentService = inject(CurrentService);
    payrollPeriodsService = inject(PayrollPeriodsService);
    translateService = inject(TranslateService);
    private readonly formBuilder = inject(FormBuilder);
    private matDialog = inject(MatDialog);
    private permissionCheckService = inject(PermissionCheckService);

    @Input({ required: true }) @SignalInput() customerId!: Signal<number>;

    gettingPeriods = signal(false);
    locked = signal(false);
    changesAwaiting = signal(false);
    dataSource = signal<PayrollPeriods | undefined>(undefined);
    columns = signal<Column[]>([]);
    columnsDef = computed(() => this.columns().flatMap((column) => column.columnDef as string));
    periods = signal<Period[]>([]);
    updateBody = signal<UpdateBody | null>(null);

    months: Months = [ 'january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december' ];

    customerYear = this.currentService.getCustomer().createdAt?.year;
    minYear = this.customerYear ? this.customerYear-1 : 2000;
    maxYear = DateTime.now().year + 15;
    year = new FormControl<number>(DateTime.now().year, { nonNullable: true, validators: [ Validators.pattern(/^\d+$/) ] });

    datesForm: FormGroup = this.formBuilder.group({});
    private formSubscription: Subscription = this.datesForm.valueChanges.subscribe();

    ngOnInit() {
        this.getPeriods();
    }

    createDatesForm() {
        this.formSubscription.unsubscribe();
        this.datesForm = this.formBuilder.group({});
        this.months.forEach((month, index) => {
            const period = this.periods()[index];
            if (index === 0) {
                this.datesForm.addControl(month, this.formBuilder.group({
                    start: [ period ? period.start : undefined, Validators.required ],
                    end: [ period ? period.end : undefined, Validators.required ],
                }));
            } else {
                this.datesForm.addControl(month, this.formBuilder.group({
                    end: [ period ? period.end : undefined, Validators.required ],
                }));
            }
        });

        this.formSubscription = this.datesForm.valueChanges.subscribe(() => {
            this.prepareAndUpdate();
        });
    }

    prepareAndUpdate() {
        const values = this.datesForm.value;
        // prepare body for update call, we need all months dates and the 'end' date
        const body: UpdateBody = {
            january: null,
            february: null,
            march: null,
            april: null,
            may: null,
            june: null,
            july: null,
            august: null,
            september: null,
            october: null,
            november: null,
            december: null,
            end: null,
        };
        // calculate start dates from end dates (beside January)
        this.months.forEach((month, index) => {
            const prevMonth = this.months[index - 1];
            if (index === 0) {
                body[month] = values[month]['start'];
            } else {
                if (prevMonth) {
                    body[month] = values[prevMonth]['end'].plus({ day: 1 });
                }
            }
        });
        const lastMonth = this.months[this.months.length - 1];
        if (lastMonth) {
            body['end'] = values[lastMonth]['end'].plus({ day: 1 });
        }
        // check body if contains all required parameters
        if (Object.values(body).some((val) => !val)) {
            // some are null or undefined, reset the table
            this.getPeriods();
            return;
        }
        this.updateBody.set(body);
        this.changesAwaiting.set(true);
    }

    createColumns() {
        const columns: any[] = [
            {
                columnDef: 'month',
                label: this.translateService.t('YEAR'),
                type: 'string',
            },
            {
                columnDef: 'start',
                label: this.translateService.t('START'),
                type: 'date',
            },
            {
                columnDef: 'end',
                label: this.translateService.t('END'),
                type: 'date',
            },
        ];

        this.columns.set(columns);
    }

    createRows() {
        const rows: Period[] = [];
        if (this.dataSource()) {
            const res = this.dataSource();
            // create all months with start dates
            this.months.forEach((month) => {
                const start = res ? res[month] : undefined;

                rows.push({
                    month,
                    label: this.translateService.t(month.toUpperCase()),
                    start,
                });
            });
            // now create end dates based on next month start dates minus 1 day
            rows.forEach((month, index) => {
                const nextRow = rows[index+1];
                if (nextRow) {
                    month.end = nextRow.start ? nextRow.start.minus({ day: 1 }) : undefined;
                } else {
                    month.end = res?.end ? res.end.minus({ day: 1 }) : undefined;
                }
            });

            this.periods.set(rows);
        }
    }

    getPeriods() {
        const year = this.year.value;
        if (!year) {
            return;
        }
        this.setGettingPeriods(true);
        this.changesAwaiting.set(false);
        this.payrollPeriodsService.get(this.customerId(), year).pipe(
            catchError((err) => {
                console.error(err);
                return EMPTY;
            }),
        ).subscribe((res) => {
            this.dataSource.set(new PayrollPeriods(res));
            this.locked.set(res.locked);
            this.createColumns();
            this.createRows();
            this.createDatesForm();
            this.setGettingPeriods(false);
        });
    }

    setGettingPeriods(gettingPeriods: boolean) {
        this.gettingPeriods.set(gettingPeriods);

        if (this.gettingPeriods()) {
            this.year.disable();
        } else {
            this.year.enable();
        }
    }

    submit() {
        if (this.updateBody()) {
            this.payrollPeriodsService.update(this.customerId(), this.year.value, this.updateBody() as Record<string, DateTime>, false).pipe(
                catchError((err) => {
                    console.error(err);
                    return EMPTY;
                }),
            ).subscribe(() => {
                this.getPeriods();
            });
        }
    }

    revert() {
        this.matDialog.open<ConfirmDialogComponent, ConfirmDialogData, ConfirmDialogReturn>(ConfirmDialogComponent, {
            data: {
                title: this.translateService.t('REVERT_PAYROLL_PERIODS', Namespace.Payroll),
                text: this.translateService.t('CONFIRM_REVERT_PAYROLL_PERIODS', Namespace.Payroll),
                confirmText: this.translateService.t('REVERT'),
            },
        }).afterClosed().pipe(catchError(() => of(undefined))).subscribe((result) => {
            if (result?.ok) {
                this.payrollPeriodsService.delete(this.customerId(), this.year.value).pipe(
                    catchError((err) => {
                        console.error(err);
                        this.getPeriods();
                        return EMPTY;
                    }),
                ).subscribe(() => {
                    this.getPeriods();
                });
            }
        });
    }

    canUpdate() {
        return this.permissionCheckService.isAllowed(`customers.${this.customerId()}.payroll_periods.update`);
    }

    canDelete() {
        return this.permissionCheckService.isAllowed(`customers.${this.customerId()}.payroll_periods.delete`);
    }

    onInputChange($event: Event) {
        const inputElement = $event.target as HTMLInputElement;
        const value = inputElement.value;
        const newVal = value.replace(/[^0-9]/g, '');
        if (newVal) {
            this.year.setValue(+newVal);
        } else {
            this.year.setValue(DateTime.now().year);
        }
    }

    lock() {
        this.matDialog.open<ConfirmDialogComponent, ConfirmDialogData, ConfirmDialogReturn>(ConfirmDialogComponent, {
            data: {
                title: this.translateService.t('LOCK_PAYROLL_PERIODS', Namespace.Payroll),
                text: this.translateService.t('CONFIRM_LOCK_PAYROLL_PERIODS', Namespace.Payroll),
                confirmText: this.translateService.t('LOCK'),
            },
        }).afterClosed().pipe(catchError(() => of(undefined))).subscribe((result) => {
            if (result?.ok) {
                this.prepareAndUpdate();
                this.payrollPeriodsService.update(this.customerId(), this.year.value, this.updateBody() as Record<string, DateTime>, true).pipe(
                    catchError((err) => {
                        console.error(err);
                        this.getPeriods();
                        return EMPTY;
                    }),
                ).subscribe(() => {
                    this.getPeriods();
                });
            }
        });
    }

    protected readonly Namespace = Namespace;
}
