<eaw-schedule-tab-menu [stackId]="stackId()"
                       [customerId]="customerId()"
                       [scheduleId]="scheduleId()"
                       (refreshStatistics)="topStatsComponent()?.refresh()"
                       (dayChange)="goToDay($event)"/>

<div id="header-container" (wheel)="onHeaderWheelEvent($event)">
    <div class="times-container">
        @for (day of days(); track day) {
            <div class="item day" [ngStyle]="{ '--schedule-tab-time-item-intervals': day.intervals }">
                <span>{{ day.day.dateTime | DateTime:'DATE_MED_WITH_WEEKDAY' }}</span>
            </div>
        }
    </div>

    @if (showTopStats()) {
        <eaw-schedule-tab-top-stats [customerId]="customerId()" [scheduleId]="scheduleId()" [renderedIntervals]="renderedIntervals()"/>
    }

    <div class="times-container">
        <div class="filler"></div>

        @for (interval of renderedIntervals(); track interval.offset) {
            <div class="item time">{{ interval.humanTime }}</div>
        }
    </div>

    <eaw-schedule-tab-create-shift-row [customerId]="customerId()"
                                       [scheduleId]="scheduleId()"
                                       [renderedIntervals]="renderedIntervals()"
                                       [pixelsPerSecond]="pixelsPerSecond()"/>
</div>

<div id="shifts-display-container" #displayContainer>
    <eaw-schedule-tab-default-display [stackId]="stackId()"
                                      [scrollOffset]="scrollOffset()"
                                      [containerWidth]="containerWidth()"
                                      [customerId]="customerId()"
                                      [renderedIntervals]="renderedIntervals()"
                                      [scheduleId]="scheduleId()"
                                      [pixelsPerSecond]="pixelsPerSecond()"/>
</div>

<div id="statusbar">
    <div id="published-status" class="status">
        @if (schedulePublished()) {
            <mat-icon eawRounded eawSize="16px" eawMaterialColor="green-500">check</mat-icon>
            <span>{{'PUBLISHED' | translate:'scheduling' | async}}</span>
        } @else {
            <mat-icon eawRounded eawSize="16px" eawMaterialColor="orange-500">close</mat-icon>
            <span>{{'UNPUBLISHED' | translate:'scheduling' | async}}</span>
        }
    </div>

    <div id="shifts" class="status" [matTooltip]="('SHIFT_STATUSBAR_TOOLTIP' | translate:'scheduling':{total: shiftsCount().all | eawNumber:0, unassigned: shiftsCount().unassigned | eawNumber:0} | async) || ''">
        <span>{{ 'SHIFT_STATUSBAR_TEXT' | translate:'scheduling':{total: shiftsCount().all | eawNumber:0, unassigned: shiftsCount().unassigned | eawNumber:0} | async }}</span>
    </div>
</div>
