import { ApiResponse } from '../../shared/interfaces/api-response';
import { DateTime } from 'luxon';
import { stringToDateTime } from '../../shared/utils/string-to-date-time';

export interface PayrollPeriodsResponse extends ApiResponse {
    id: number | null;
    customer_id: number;
    year: number;
    locked: boolean;
    january: string;
    february: string;
    march: string;
    april: string;
    may: string;
    june: string;
    july: string;
    august: string;
    september: string;
    october: string;
    november: string;
    december: string;
    end: string;
}

export class PayrollPeriods {
    id: number | null;
    customerId: number;
    year: number;
    locked: boolean;
    january: DateTime;
    february: DateTime;
    march: DateTime;
    april: DateTime;
    may: DateTime;
    june: DateTime;
    july: DateTime;
    august: DateTime;
    september: DateTime;
    october: DateTime;
    november: DateTime;
    december: DateTime;
    end: DateTime;
    currentPeriod?: { from: DateTime, to: DateTime };

    constructor(data: PayrollPeriodsResponse) {
        this.id = data.id;
        this.customerId = data.customer_id;
        this.year = data.year;
        this.locked = data.locked;
        this.january = stringToDateTime(data.january);
        this.february = stringToDateTime(data.february);
        this.march = stringToDateTime(data.march);
        this.april = stringToDateTime(data.april);
        this.may = stringToDateTime(data.may);
        this.june = stringToDateTime(data.june);
        this.july = stringToDateTime(data.july);
        this.august = stringToDateTime(data.august);
        this.september = stringToDateTime(data.september);
        this.october = stringToDateTime(data.october);
        this.november = stringToDateTime(data.november);
        this.december = stringToDateTime(data.december);
        this.end = stringToDateTime(data.end);
        if (data.year === DateTime.now().year) {
            this.currentPeriod = { from: DateTime.now(), to: DateTime.now() };
            const currentMonth: string = DateTime.now().monthLong.toLowerCase();
            const nextMonth: string = DateTime.now().plus({ month: 1 }).monthLong.toLowerCase();
            Object.entries(data).forEach(([ key, value ]) => {
                if (key === currentMonth) {
                    if (this.currentPeriod) {
                        this.currentPeriod.from = stringToDateTime(value as string);
                    }
                } else if (key === nextMonth) {
                    if (this.currentPeriod) {
                        this.currentPeriod.to = stringToDateTime(value as string);
                        this.currentPeriod.to = this.currentPeriod.to.minus({ days: 1 });
                    }
                }
            });
        }
    }
}
