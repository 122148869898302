import { Inject, Injectable } from '@angular/core';
import { UIRouter } from '@uirouter/core';
import { Transition } from '@uirouter/angularjs';
import { SidenavTabsRegisterService, SidenavTabsRegisterServiceData } from '../../shared/services/sidenav-tabs-register.service';
import { SidenavTab } from '../../shared/components/sidenav/sidenav.component';
import { MyCompanyComponent } from '../pages/my-company/my-company.component';
import { Namespace } from '../../shared/enums/namespace';
import { resolver } from '../../shared/resolvers/resolver';
import { CurrentService } from '../../shared/services/current.service';
import { CompanySettingsComponent } from '../pages/company-settings/company-settings.component';
import { positionsUpgradedComponent } from '../angularjs/positions/positions.component.upgrade';
import { CompanyQualificationsComponent } from '../pages/company-qualifications/company-qualifications.component';
import { OpeningHoursComponent } from '../pages/opening-hours/opening-hours.component';
import { CompanyBusinessUnitsComponent } from '../../business-units/pages/company-business-units/company-business-units.component';
import { Products } from '../../shared/enums/products';
import { ShiftSettingsComponent } from '../pages/shift-settings/shift-settings.component';
import { SocialRulesSettingsComponent } from '../pages/social-rules-settings/social-rules-settings.component';
import { PayrollConfigurationComponent } from '../pages/payroll-configuration/payroll-configuration.component';
import { TemplateListComponent } from '../../scheduling/pages/template-list/template-list.component';
import { RoleAssignmentListComponent } from '../angularjs/settings/roles/role-assignment-list.component';
import { companySettingsContractTypesUpgradedComponent } from '../angularjs/settings/contract-types/contract-types-upgrade.component';
import { companyAbsenceTypesUpgradedComponent } from '../angularjs/settings/absence-types/absence-types.upgraded.component';
import { companySettingsTariffsUpgradedComponent } from '../angularjs/settings/tariffs/tariffs.upgraded.component';
import { companySettingsPayTypesUpgradedComponent } from '../angularjs/settings/pay-types/pay-types.upgraded.component';
import { staffingTemplatesUpgradedComponent } from '../../staffing/angularjs/templates/staffing-templates-upgraded.component';
import { weekSetupListUpgradedComponent } from '../../staffing/angularjs/week-setup/list/week-setup-list-upgrade.component';
import { PermissionCheckService } from '../../shared/services/permission-check.service';
import { firstValueFrom } from 'rxjs';
import { DataTableQueryParams } from '../../data-table/data-table.component';
import { CompanyVlhComponent } from '../pages/company-vlh/company-vlh.component';
import { currentResolver } from '../../shared/resolvers/current.resolver';
import { PayrollPeriodsComponent } from '../pages/payroll-periods/payroll-periods.component';
import { PredefinedSettings } from '../../shared/enums/predefined-settings';

@Injectable({
    providedIn: 'root',
})
export class CompanySettingsSidenavService extends SidenavTabsRegisterService {
    constructor(@Inject(UIRouter) override router: UIRouter) {
        super(router);
    }

    init(data: SidenavTabsRegisterServiceData) {
        this.registerTabs(data, this.getTabs(data));
    }

    private getTabs(data: SidenavTabsRegisterServiceData): SidenavTab[] {
        return [
            {
                url: '/my_company',
                state: `${data.sidenavState}/my_company`,
                component: MyCompanyComponent,
                data: {
                    breadcrumb: { key: 'MY_INFORMATION', ns: Namespace.Company },
                    permissions: [ `customers.{customer}.get` ],
                },
            },
            {
                url: '/settings',
                state: `${data.sidenavState}/settings`,
                component: CompanySettingsComponent,
                data: {
                    breadcrumb: { key: 'SETTINGS', ns: Namespace.Navigation },
                    permissions: [ `customers.{customer}.settings.manage`, 'customers.{customer}.properties.*.update' ],
                },
            },
            {
                url: '/templates',
                state: `${data.sidenavState}/templates`,
                component: TemplateListComponent,
                data: {
                    queryParams: DataTableQueryParams,
                    breadcrumb: { key: 'TEMPLATES', ns: Namespace.Navigation },
                    products: [ Products.France ],
                    permissions: [ 'customers.{customer}.schedules.*.get' ],
                },
            },
            {
                url: '/qualifications',
                state: `${data.sidenavState}/qualifications`,
                component: CompanyQualificationsComponent,
                data: {
                    breadcrumb: { key: 'QUALIFICATIONS', ns: Namespace.Navigation },
                    permissions: [ 'customers.{customer}.qualifications.*.get' ],
                },
            },
            {
                url: '/business_units',
                state: `${data.sidenavState}/business_units`,
                component: CompanyBusinessUnitsComponent,
                data: {
                    breadcrumb: { key: 'BUSINESS_UNIT' },
                    products: [ Products.BusinessUnits ],
                    permissions: [ 'customers.{customer}.business_units.*.get' ],
                },
            },
            {
                url: '/positions',
                state: `${data.sidenavState}/positions`,
                component: positionsUpgradedComponent,
                data: {
                    breadcrumb: { key: 'POSITIONS', ns: Namespace.Navigation },
                    permissions: [ 'customers.{customer}.positions.*.get' ],
                },
                resolve: [
                    resolver('canCreate', (transition: Transition) => {
                        const currentService = transition.injector().get(CurrentService) as CurrentService;
                        const permissionCheckService = transition.injector().get(PermissionCheckService) as PermissionCheckService;
                        return firstValueFrom(permissionCheckService.isAllowed('customers.' + currentService.getCustomer().id + '.positions.create'));
                    }),
                ],
            },
            {
                url: '/shift_settings',
                state: `${data.sidenavState}/shift_settings`,
                component: ShiftSettingsComponent,
                data: {
                    breadcrumb: { key: 'SHIFT_CONFIG_plural', ns: Namespace.Company },
                    permissions: [ 'customers.{customer}.shift_configurations.create' ],
                    products: [ Products.OpsManagerDashboard ],
                },
            },
            {
                url: '/social_rules',
                state: `${data.sidenavState}/social_rules`,
                component: SocialRulesSettingsComponent,
                data: {
                    breadcrumb: { key: 'SOCIAL_RULE_plural', ns: Namespace.FranceSocialRules },
                    permissions: [ 'customers.{customer}.properties.*.update', 'social_rules' ],
                    products: [ Products.France ],
                },
            },
            {
                url: '/payroll_configuration',
                state: `${data.sidenavState}/payroll_configuration`,
                component: PayrollConfigurationComponent,
                data: {
                    breadcrumb: { key: 'PAYROLL_CONFIGURATION', ns: Namespace.Company },
                    permissions: [ 'customers.{customer}.properties.*.update', 'customers.{customer}.payroll_configuration.update' ],
                    products: [ Products.France ],
                },
            },
            {
                url: '/payroll_periods',
                state: `${data.sidenavState}/payroll_periods`,
                component: PayrollPeriodsComponent,
                data: {
                    breadcrumb: { key: 'PAYROLL_PERIOD_plural', ns: Namespace.Payroll },
                    permissions: [ 'customers.{customer}.payroll_periods.*.get' ],
                    settings: {
                        [PredefinedSettings.PayrollPeriodsEnabled]: (decoder) => decoder?.asBoolean() === true,
                    },
                },
            },
            {
                url: '/opening_hours',
                state: `${data.sidenavState}/opening_hours`,
                component: OpeningHoursComponent,
                data: {
                    breadcrumb: { key: 'OPENING_HOURS', ns: Namespace.Navigation },
                    permissions: [ 'customers.{customer}.opening_hours.create' ],
                },
            },
            {
                url: '/staffing_templates',
                state: `${data.sidenavState}/staffing_templates`,
                component: staffingTemplatesUpgradedComponent,
                data: {
                    breadcrumb: { key: 'STAFFING_TEMPLATES', ns: Namespace.Navigation },
                    products: [ Products.France, Products.Staffing ],
                    permissions: [ 'customers.{customer}.vs_templates.*.get' ],
                },
            },
            {
                url: '/vlh',
                state: `${data.sidenavState}/vlh`,
                component: CompanyVlhComponent,
                data: {
                    breadcrumb: { key: 'VLH', ns: Namespace.Company },
                    products: [ Products.VLH ],
                    permissions: [ 'customers.{customer}.schedules.create' ],
                },
                resolve: [
                    currentResolver('customerId', 'customer', 'id'),
                ],
            },
            {
                url: '/week_setup',
                state: `${data.sidenavState}/week_setup`,
                component: weekSetupListUpgradedComponent,
                data: {
                    i18nextNs: [ Namespace.Staffing ],
                    breadcrumb: { key: 'WEEK_SETUP', ns: Namespace.Company },
                    products: [ Products.France ],
                    permissions: [ 'customers.{customer}.week_setups.*.get' ],
                },
            },
            {
                url: '/contract_types',
                state: `${data.sidenavState}/contract_types`,
                component: companySettingsContractTypesUpgradedComponent,
                data: {
                    breadcrumb: { key: 'CONTRACT_TYPES' },
                    permissions: [ `setting_groups.{customer.setting_group_id}.contract_types.*.get` ],
                },
            },
            {
                url: '/absence_types',
                state: `${data.sidenavState}/absence_types`,
                component: companyAbsenceTypesUpgradedComponent,
                data: {
                    breadcrumb: { key: 'ABSENCE_TYPE_plural', ns: Namespace.AbsenceTypes },
                    i18nextNs: [ Namespace.Absences, Namespace.Vacation, Namespace.AbsenceTypes ],
                    permissions: [ 'customers.{customer.id}.absence_types.*.get' ],
                },
            },
            {
                url: '/tariffs',
                state: `${data.sidenavState}/tariffs`,
                component: companySettingsTariffsUpgradedComponent,
                data: {
                    breadcrumb: { key: 'TARIFF_plural', ns: Namespace.Company },
                    permissions: [ 'setting_groups.{customer.setting_group_id}.tariffs.*.get' ],
                },
            },
            {
                url: '/pay_types',
                state: `${data.sidenavState}/pay_types`,
                component: companySettingsPayTypesUpgradedComponent,
                data: {
                    breadcrumb: { key: 'PAY_TYPES', ns: Namespace.Navigation },
                    permissions: [ 'customers.{customer}.pay_type_links.*.get' ],
                },
            },
            {
                url: '/roles',
                state: `${data.sidenavState}/roles`,
                component: RoleAssignmentListComponent,
                data: {
                    queryParams: DataTableQueryParams,
                    breadcrumb: { key: 'ROLE_plural', ns: Namespace.Company },
                    permissions: [ 'customers.{customer}.roles.*.get' ],
                },
            },
        ];
    }
}
