import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PayrollPeriodsResponse } from '../../company/models/payroll-periods';
import { DateTimeConverter } from '../../shared/utils/date-time-converter';
import { DateTime } from 'luxon';

@Injectable({
    providedIn: 'root',
})
export class PayrollPeriodsService {
    private http = inject(HttpClient);

    get(customerId: number, year: number) {
        return this.http.get<PayrollPeriodsResponse>(`customers/${customerId}/payroll_periods/${year}`);
    }

    update(customerId: number, year: number, body: Record<string, DateTime | string | boolean>, lock = false) {
        Object.entries(body).forEach(([ key, value ]) => {
            body[key] = DateTimeConverter.convertDateTimeToUtcString(value) || '';
        });
        if (lock) {
            body['lock'] = true;
        }
        return this.http.put(`customers/${customerId}/payroll_periods/${year}`, body);
    }

    delete(customerId: number, year: number) {
        return this.http.delete(`customers/${customerId}/payroll_periods/${year}`);
    }
}
